import React from "react";
import { graphql } from "gatsby";

import Seo from "../components/seo";
import { LocaleContext } from "../components/layout";
import Logos from "../components/logos";

export default function Impressum({ data }) {
  const { locale, url } = React.useContext(LocaleContext);
  const page = data.markdownRemark;

  return (
    <>
      <Seo
        url={url}
        lang={locale}
        title={page.frontmatter.title}
        description={page.frontmatter.metaDescription}
        bodyClass="page-impressum"
      />
      <div className="impressum container">
        <div className="impressum__row row">
          <div className="impressum__column">
            <div className="impressum__column-wrapper">
              <h1 className="impressum__title">{page.frontmatter.title}</h1>

              <div
                className="impressum__credits"
                dangerouslySetInnerHTML={{ __html: page.frontmatter.credits }}
              />

              <Logos />
              <div
                className="impressum__description"
                dangerouslySetInnerHTML={{ __html: page.html }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const query = graphql`
  query ($locale: String!) {
    markdownRemark(
      frontmatter: { template: { eq: "impressum" } }
      fields: { locale: { eq: $locale } }
    ) {
      html
      frontmatter {
        title
        metaDescription
        credits
      }
    }
  }
`;
