import React from "react";

import { StaticImage } from "gatsby-plugin-image";

const Logos = () => {
  return (
    <div className="logos">
      <div className="logos__row">
        <StaticImage
          src="../images/logo-asa-ff.png"
          alt="Logo ASA FF"
          placeholder="blurred"
          width={165}
          className="logos__image"
        />

        <StaticImage
          src="../images/logo-offener-prozess.png"
          alt="Logo Offener Prozess"
          placeholder="blurred"
          width={120}
          className="logos__image"
        />

        <StaticImage
          src="../images/logo-please-dont-touch.svg"
          alt="Logo Please Don't touch"
          placeholder="blurred"
          width={160}
          className="logos__image"
        />
      </div>

      <h3 className="logos__title">
        Die Ausstellung Offener Prozess wird gefördert von:
      </h3>
      <div className="logos__row">
        <StaticImage
          src="../images/logo-kulturstiftung-des-freistaates-sachsen.png"
          alt="Logo Kulturstiftung des Freistaates Sachsen"
          placeholder="blurred"
          height={100}
          className="logos__image"
        />

        <StaticImage
          src="../images/logo-bpb.png"
          alt="Logo BPB"
          placeholder="blurred"
          height={100}
          className="logos__image"
        />

        <StaticImage
          src="../images/logo-chemnitz-stadt-der-moderne.png"
          alt="Logo Chemnitz Stadt der Moderne"
          placeholder="blurred"
          height={100}
          className="logos__image"
        />
      </div>
      <h3 className="logos__title">
        Das Projekt Offener Prozess wird gefördert von:
      </h3>
      <div className="logos__row">
        <StaticImage
          src="../images/logo-weltoffenessachsen.png"
          alt="Logo Weltoffenes Sachsen"
          placeholder="blurred"
          height={100}
          className="logos__image"
        />
      </div>

      <div className="logos__row">
        <StaticImage
          src="../images/logo-chemnitz-Kulturhauptstadt.png"
          alt="Logo Chemnitz Kulturhauptstadt"
          placeholder="blurred"
          height={60}
          className="logos__image"
        />
      </div>

      <div className="logos__row">
        <StaticImage
          src="../images/logo-DZLogoleiste.png"
          alt="Logo Demokratie leben"
          placeholder="blurred"
          height={100}
          className="logos__image"
        />
      </div>

      <div className="logos__row">
        <StaticImage
          src="../images/logo-amadeu-antonio-stiftung.png"
          alt="Logo Amadeu Antonio Stiftung"
          placeholder="blurred"
          height={60}
          className="logos__image"
        />

        <StaticImage
          src="../images/logo-cellex-foundation.png"
          alt="Logo Cellex Foundation"
          placeholder="blurred"
          height={60}
          className="logos__image"
        />
      </div>
    </div>
  );
};
export default Logos;
